var render = function render(){var _vm=this,_c=_vm._self._c;return _c('carousel',{staticClass:"price-cards",attrs:{"per-page":4,"navigation-enabled":true}},[_c('slide',{},[_c('div',{staticClass:"card relative _darker"},[_c('div',{staticClass:"card-header"},[_vm._v("EARLY BIRD")]),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("40")]),_vm._v(" "),_c('span',{staticClass:"card-text",domProps:{"textContent":_vm._s(
            _vm._.has(_vm.translations, 'galaDinnerOptional')
              ? _vm.translations.galaDinnerOptional
              : 'Gala dinner included (optional)'
          )}}),_vm._v(" "),_c('span',[_vm._v(" ")])])])]),_vm._v(" "),_c('slide',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v("REGULAR")]),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("60")]),_vm._v(" "),_c('span',{staticClass:"card-text",domProps:{"textContent":_vm._s(
            _vm._.has(_vm.translations, 'galaDinnerOptional')
              ? _vm.translations.galaDinnerOptional
              : 'Gala dinner included (optional)'
          )}}),_vm._v(" "),_c('span',[_vm._v(" ")])])])]),_vm._v(" "),_c('slide',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v("UNIZG")]),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("10")])])])]),_vm._v(" "),_c('slide',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v("MEF ZG")]),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("0")])])])]),_vm._v(" "),_c('slide',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header text-xs"},[_vm._v("MEF ZG - alumni")]),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("30")])])])]),_vm._v(" "),_c('slide',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header",domProps:{"textContent":_vm._s(
          _vm._.has(_vm.translations, 'galaDinner')
            ? _vm.translations.galaDinner
            : 'GALA VEČERA'
        )}}),_vm._v(" "),_c('hr',{staticClass:"break-line"}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"price"},[_c('sup',{staticClass:"price-euro"},[_vm._v("€")]),_vm._v("25")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }