<template>
  <vue-countdown
    tag="div"
    class="row count-down"
    :time="this.time"
    :transform="transformSlotProps"
    v-slot="{ days, hours, minutes, seconds }"
  >
    <div class="col-xs-6 col-md-3">
      <div class="time-container">
        <div class="time-block" v-html="days"></div>
        <span>{{ translations.days }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-md-3">
      <div class="time-container">
        <div class="time-block" v-html="hours"></div>
        <span>{{ translations.hours }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-md-3">
      <div class="time-container">
        <div class="time-block" v-html="minutes"></div>
        <span>{{ translations.minutes }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-md-3">
      <div class="time-container">
        <div class="time-block" v-html="seconds"></div>
        <span>{{ translations.seconds }}</span>
      </div>
    </div>
  </vue-countdown>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  props: {
    translations: {
      type: Object,
      required: true,
      default: () => ({
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
      }),
    },
    timeFrom: {
      type: String,
      required: true,
    },
  },

  components: {
    VueCountdown,
  },

  data() {
    return {
      time: null,
    };
  },

  created() {
    const now = new Date();
    const until = new Date(this.timeFrom);
    this.time = until - now;
  },

  methods: {
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);

        if (["days", "minutes", "hours", "seconds"].includes(key)) {
          formattedProps[key] = formattedProps[key]
            .split("")
            .reduce((value, char) => {
              return `${value}<span>${char}</span>`;
            }, "");
        }
      });

      return formattedProps;
    },
  },
};
</script>
