import Vue from "vue";
import has from "lodash/has";

import WorkshopItem from "./components/WorkshopItem";
import Lecturer from "./components/Lecturer";
import ScheduleChunk from "./components/ScheduleChunk";
import TicketBox from "./components/TicketBox";
import Countdown from "./components/Countdown";

Vue.prototype._ = {
  has,
};

Vue.config.productionTip = false;

if (document.querySelector(".app-timer")) {
  const AppTimer = new Vue({
    el: ".app-timer",

    components: {
      Countdown,
    },
  });

  window.AppTimer = AppTimer;
}

if (document.querySelector(".app-timer")) {
  const App = new Vue({
    el: ".app",

    components: {
      WorkshopItem,
      Lecturer,
      ScheduleChunk,
      TicketBox,
      Countdown,
    },

    data: {
      auth: window.auth,
    },
  });

  window.App = App;
}
