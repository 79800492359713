<template>
    <div class="col-xs-12 list-item lecturer">
        <div class="card">
            <figure class="cover" :style="`background-image: url('${item.image}')`"></figure>
            <div class="info">
                <h4 v-html="item.lecturer_name + '<span>, ' + item.lecturer_title + '</span>'"></h4>
                <a :href="item.link" class="lecture" v-text="item.lecture"></a>
                <span class="institute" v-text="item.institute"></span>

                <button class="more-info" @click="toggleCollapse" v-text="buttonText"></button>
            </div>
            <div
                class="additional-info-container"
                :class="isCollapsed ? 'collapsed' : ''">
                <div class="additional-info" v-html="item.biography"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'item', 'buttonText'
    ],

    data() {
        return {
            isCollapsed: false,
        }
    },

    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
        }
    }
}
</script>
