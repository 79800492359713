<template>
    <div
        class="col-xs-12 list-item schedule"
        :id="item.category == 'lectures' ? item.description_slug : ''"
        :class="item.category == 'social' ? 'is-social' : ''">
        <div class="card">
            <div class="schedule-timespan">
                <span class="time" v-text="item.timespan"></span>
                <span v-text="item.mapped_category"></span>
            </div>
            <div class="info">
                <a class="h4" :href="item.url" v-text="item.title"></a>
                <span v-text="item.description" class="desc" v-if="item.description"></span>
                <span v-text="item.location" class="location"></span>

                <button v-if="item.biography" class="more-info" @click="toggleCollapse" v-text="buttonText"></button>

                <div
                    v-if="item.biography"
                    class="additional-info-container"
                    :class="isCollapsed ? 'collapsed' : ''">
                    <div class="additional-info" v-html="item.biography"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'item', 'timespan', 'buttonText'
    ],

    data() {
        return {
            isCollapsed: false,
        }
    },

    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
        }
    }
}
</script>
