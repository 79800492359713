<template>
    <div class="col-xs-12 col-md-6 list-item _workshop">
        <div class="card">
            <figure :style="`background-image: url('${item.image}')`"></figure>
            <div class="info">
                <h4 v-text="item.workshop_name"></h4>
                <span class="section" v-text="item.section_name"></span>
                <span class="location" v-text="item.workshop_location"></span>

                <button class="more-info" @click="toggleCollapse" v-text="buttonText"></button>
            </div>
            <div
                class="additional-info-container"
                :class="isCollapsed ? 'collapsed' : ''">
                <div class="additional-info" v-html="item.short_description"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'item', 'buttonText'
    ],

    data() {
        return {
            isCollapsed: false,
        }
    },

    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
        }
    }
}
</script>
